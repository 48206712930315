export default [
    {
        "title":"排名不分先后"
    },

    {
        "name":"井贤栋",
        "company":"蚂蚁集团董事长兼CEO"
    },

    {
        "name":"刘庆峰",
        "company":"科大讯飞董事长"
    },

    {
        "name":"李建宇",
        "company":"华菱钢铁董事长、总经理"
    },

    {
        "name":"李三希",
        "company":"中国人民大学教授、数字经济研究中心主任"
    },

    {
        "name":"姚劲波",
        "company":"58同城CEO"
    },

    {
        "name":"刘经南",
        "company":"中国工程院院士、武汉大学教授、大地测量与卫星导航专家"
    },

    {
        "name":"杨伟军",
        "company":"华为公司高级副总裁"
    },

    {
        "name":"唐岳",
        "company":"楚天科技董事长、湖湘会成员"
    },

    {
        "name":"李俊峰",
        "company":"国家气候战略中心首任主任、中国能源研究会常务理事"
    },

    {
        "name":"李传军",
        "company":"中科曙光集团副总裁"
    },

    {
        "name":"陈维良",
        "company":"沐曦集成电路（上海）有限公司创始人、董事长兼CEO"
    },

    {
        "name":"姚锦聪",
        "company":"嘉士德资本CEO"
    },

    {
        "name":"周烔",
        "company":"京东集团副总裁、IOT事业部负责人"
    },
    {
        "name":"孙海",
        "company":"中国电信集团公司智能制造研究院院长，高级工程师"
    },

    {
        "name":"姚一兆",
        "company":"腾讯云副总裁、腾讯云计算（长沙）有限责任公司董事长"
    },

    {
        "name":"谢庆林",
        "company":"中国电子信息产业集团有限公司总经理助理、中国长城科技集团股份有限公司党委书记、董事长"
    },

    {
        "name":"刘湘雯",
        "company":"阿里云智能集团市场部总裁"
    },

    {
        "name":"熊晓鸽",
        "company":"IDG资本全球董事长、哈佛大学商学院亚洲顾问委员会委员"
    },

    {
        "name":"李安民",
        "company":"北京久银投资控股股份有限公司董事长、<br>北京长沙企业商会会长"
    },

    {
        "name":"李青",
        "company":"上海市新沪商联合会轮值会长、上海市长沙商会创会会长、上海市湖南商会执行会长"
    },

    {
        "name":"曾胜强",
        "company":"深圳长沙商会会长、深圳证通电子股份有限公司董事长"
    },

    {
        "name":"刘海东",
        "company":"聚和材料董事长"
    },

    {
        "name":"陈方明",
        "company":"博雷顿科技股份公司董事长"
    },

    {
        "name":"陈文正",
        "company":"前海股权投资基金（有限合伙）执行合伙人、前海方舟董事、总裁"
    },

    {
        "name":"沈晖",
        "company":"威马汽车董事长"
    },

    {
        "name":"倪正东",
        "company":"清科集团创始人、董事长兼CEO"
    },

    {
        "name":"李新宇",
        "company":"拓维信息创始人、董事长"
    },

    {
        "name":"吴太兵",
        "company":"万兴科技创始人、董事长兼总裁"
    },

    {
        "name":"戴跃锋",
        "company":"水羊集团创始人、董事长"
    },

    {
        "name":"陈政",
        "company":"湖南趣恋科技有限公司董事长"
    }
   
]