var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-wrapper"},[_c('van-sticky',{staticClass:"header",attrs:{"type":"primary"}},[_c('head-bar',{attrs:{"title":'嘉宾列表'},on:{"onClickLeft":function($event){return _vm.$router.go(-1)}}})],1),_c('div',{staticClass:"content"},[_vm._l((_vm.userList),function(item,index){return [(item.title)?[_c('div',{staticClass:"title"},[_c('img',{attrs:{"src":"https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202306072000232167646.png","alt":""}}),_c('span',[_vm._v(_vm._s(item.title))])]),_c('div',{staticClass:"sub-title"},[_vm._v(_vm._s(item.subTitle))])]:[_c('div',{staticClass:"user-list-wrapper"},[_c('div',{staticClass:"user-item",class:[index % 2 === 0 ? '' : 'right'],style:({
              background:
                index % 2 === 0
                  ? 'linear-gradient(to right,rgb(62, 84, 172), rgba(73, 66, 228,0))'
                  : 'linear-gradient(to right, rgba(16,13,67, 0), rgba(16,13,67, 1))',
              borderRadius: index % 2 === 0 ? '100px 0 0 100px' : '0 100px 100px 0',
            })},[_vm._m(0,true),_c('div',{staticClass:"txt-wrapper"},[_c('div',{staticClass:"username",style:({
                  textAlign: index % 2 === 0 ? 'left' : 'right',
                  marginLeft: index % 2 === 0 ? '0' : '60%',
                })},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"desc",style:({
                  textAlign: index % 2 === 0 ? 'left' : 'right',
                }),domProps:{"innerHTML":_vm._s(item.company)}})])])])]]}),_c('div',{staticClass:"more"},[_vm._v("持续更新中...")])],2)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":"https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202306072000232167646.png","alt":""}})])
}]

export { render, staticRenderFns }