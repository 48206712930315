<template>
  <div class="list-wrapper">
    <van-sticky type="primary" class="header">
      <head-bar :title="'嘉宾列表'" @onClickLeft="$router.go(-1)" />
    </van-sticky>
    <div class="content">
      <template v-for="(item, index) in userList">
        <template v-if="item.title">
          <div class="title">
            <img
              src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202306072000232167646.png"
              alt=""
            />
            <span>{{item.title}}</span>
          </div>
          <div class="sub-title">{{item.subTitle}}</div>
        </template>

        <template v-else>
          <div class="user-list-wrapper">
            <div
              class="user-item"
              :class="[index % 2 === 0 ? '' : 'right']"
              :style="{
                background:
                  index % 2 === 0
                    ? 'linear-gradient(to right,rgb(62, 84, 172), rgba(73, 66, 228,0))'
                    : 'linear-gradient(to right, rgba(16,13,67, 0), rgba(16,13,67, 1))',
                borderRadius: index % 2 === 0 ? '100px 0 0 100px' : '0 100px 100px 0',
              }"
            >
              <!--      ? 'linear-gradient(to right, rgba(0, 157, 255, 1), #192f6a);' -->
              <div class="img-wrapper">
                <img
                  src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202306072000232167646.png"
                  alt=""
                />
              </div>
              <div class="txt-wrapper">
                <div
                  class="username"
                  :style="{
                    textAlign: index % 2 === 0 ? 'left' : 'right',
                    marginLeft: index % 2 === 0 ? '0' : '60%',
                  }"
                >
                  {{ item.name }}
                </div>
                <div
                  class="desc"
                  :style="{
                    textAlign: index % 2 === 0 ? 'left' : 'right',
                  }"
                  v-html="item.company"
                >
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>

      <div class="more">持续更新中...</div>
    </div>
  </div>
</template>

<script>
import headBar from "@/components/headBar/headBar";
import userList from "./data";
export default {
  name: "",
  components: {
    headBar,
  },
  data() {
    return {
      userList,
    };
  },
  mounted() {
    console.log(userList);
  },
};
</script>

<style lang="less" scoped>
.list-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: url(https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202306062004234495727.jpg);
  background-size: 100% 100%;
  color: white;
  .header {
    // height: 50px;
  }
  .content {
    flex: 1;
    overflow: scroll;
    padding-bottom: 30px;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      margin-top: 15px;
      font-size: 12px;
      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
    .sub-title {
      font-size: 12px;
      text-align: center;
    }

    .user-list-wrapper {
      .user-item {
        // background-color: #1c3876;
        // background: linear-gradient(to right, #192f6a, #0a192f ,#192f6a);
        // background: linear-gradient(to right, #6177b6,#0b1ca1);
        width: 90%;
        margin-left: 5%;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 10px;
        // border-radius: 4px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        // background: linear-gradient(to right,rgb(62, 84, 172), rgba(73, 66, 228,0));
        .img-wrapper {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
        }

        .txt-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: 1;
          width: 65%;
          // background: linear-gradient(to right, rgba(0, 157, 255, 0), #192f6a);
          .username {
            font-size: 14px;
            width: 40%;
            font-weight: 500;
            padding: 4px 12px;
          }
          .desc {
            // border-top: 1px solid #ccc;
            font-size: 12px;
            color: #fff;
            width: 100%;
            // background: linear-gradient(to right, rgba(0, 157, 255, 0), #192f6a);
            // padding: 12px;
            padding: 2px 12px;
            position: relative;
          }
        }
      }
    }
  }
}

.right {
  flex-direction: row-reverse;
  img {
    margin-left: 8px;
    margin-right: 0;
  }
  .text-wrapper {
    margin-right: 8px !important;
    margin-left: 0 !important;
    align-items: flex-end !important;
    .username {
      background: linear-gradient(to left, rgba(0, 157, 255, 0), #192f6a) !important;
    }
    .desc {
      background: linear-gradient(to left, rgba(0, 157, 255, 0), #192f6a) !important;
    }
  }
}

.more {
  text-align: center;
  padding: 20px;
  color: white;
  font-size: 14px;
}
</style>
